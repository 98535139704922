<template>
  <vx-card class="user-profile-page"
           :class="{'main-box top-zero-radius': !userId, 'no-box-shadow': userId > 0}"
           v-if="user && Object.keys(user).length > 0">

    <profile-main-box :profile="getUserProfile"
                      :default-action="profileStatus"
                      :actionsList="actionsList"
                      @userTransactions="handleChangeProfileStatus('userTransactions')"
                      @userMaxDebt="handleChangeProfileStatus('userMaxDebt')"
                      @userInvoices="handleChangeProfileStatus('userInvoices')"
                      @insertSaleInvoice="handleChangeProfileStatus('insertSaleInvoice')"
                      @customerSeasonSchedulesList="handleChangeProfileStatus('customerSeasonSchedulesList')"
                      @userClasses="handleChangeProfileStatus('userClasses')"
                      @insertTreasuryReceive="handleChangeProfileStatus('insertTreasuryReceive')"
                      @userActivitiesLog="handleChangeProfileStatus('userActivitiesLog')">

      <user-transactions v-if="profileStatus === 'userTransactions'" :user-id="userId || $route.params.id"/>

      <set-user-max-debt v-if="profileStatus === 'userMaxDebt'" :user-id="userId || parseInt($route.params.id)" :user-max-debt="user.financial.maxDebt" @setMaxDebt="handleChangeProfileStatus('userTransactions'), getUser(), $emit('edited')"/>

      <user-invoices-list v-if="profileStatus === 'userInvoices'" :user-id="userId || parseInt($route.params.id)"/>

      <insert-time-sale-invoice v-if="profileStatus === 'insertSaleInvoice'" class="in-modal" :user-id="userId || parseInt($route.params.id)" @inserted="handleChangeProfileStatus('userTransactions')" />

      <season-schedules-list v-if="profileStatus === 'customerSeasonSchedulesList'" :user-id="userId || parseInt($route.params.id)" />

      <coach-classes-list v-if="profileStatus === 'userClasses'" :coach-id="userId || parseInt($route.params.id)"/>

      <insert-receipt v-if="profileStatus === 'insertTreasuryReceive'" :user-id="userId || parseInt($route.params.id)" @inserted="handleChangeProfileStatus('userTransactions'), getUser()" />

      <user-activities-log v-if="profileStatus === 'userActivitiesLog'" :user-id="userId || parseInt($route.params.id)" />

      <edit-user v-if="profileStatus === 'editUser'" :user-id="userId || this.$route.params.id"/>
    </profile-main-box>

    <button v-show="false" id="editUser" @click="handleChangeProfileStatus('editUser')"></button>
  </vx-card>
</template>

<script>
import ProfileMainBox from '@/components/profileMainBox/profileMainBox2.vue'
import CustomIcon from '../../../../components/customIcon/customIcon'
import InsertFastReceive from "../../treasury/receive/insert/insertFastReceive";
import UserTransactions from "../transactions/userTransactions";
import SetUserMaxDebt from '../maxDebt/setUserMaxDebt'
import UserInvoicesList from "../invoices/userInvoices";
import CoachClassesList from "../classes/coachClassesList";
import EditUser from "@/views/admin/customers/edit/editUser.vue";
import UserActivitiesLog from "@/views/admin/customers/activitiesLog/userActivitiesLog.vue";
import InsertTimeSaleInvoice from "@/views/admin/sales/invoices/insert/insertTimeSaleInvoice.vue";
import SeasonSchedulesList from "@/views/admin/customers/seasonSchedules/seasonSchedulesList.vue";
import InsertReceipt from "@/views/admin/treasury/receive/insert/insertReceive.vue";
import {getUser} from '@/http/requests/users/users'
import {addComma, checkUserPermissions, getAvatarUrl} from '@/assets/js/functions'

export default {
  name: 'userProfile',
  components: {
    InsertReceipt,
    SeasonSchedulesList,
    InsertTimeSaleInvoice,
    UserActivitiesLog,
    EditUser,
    CoachClassesList,
    UserInvoicesList,
    UserTransactions,
    SetUserMaxDebt,
    InsertFastReceive,
    CustomIcon,
    ProfileMainBox
  },
  props: {
    userId: {
      type: [Number, String],
      default: 0
    }
  },
  metaInfo () {
    return {
      title: this.user ? this.$t('users.user.profile.dynamicTitle', {name: `${this.user.general.name.value} ${this.user.general.lastName.value}`}) : this.$t('users.user.profile.title')
    }
  },
  data () {
    return {
      profileStatus: 'userTransactions',
      actions: {
        toolbar: [
          {
            id: {name: 'editUser', params: {id: this.$route.params.id}},
            type: 'link',
            icon: 'EDIT',
            iconPack: 'useral',
            color: 'warning',
            permission: 'user.update'
          }
        ],
        leftToolbar: [
          {
            id: 'routeBackBTN',
            route: {name: 'customers'},
            icon: 'CHEVRON_LEFT',
            iconPack: 'useral'
          }
        ]
      },
      user: null,
      actionsList: [
        {
          event: 'userTransactions',
          name: 'userTransactions',
          params: {id: this.userId || this.$route.params.id},
          header: this.$t('users.user.profile.actions.transactions'),
          extra: {
            value: '',
            classes: 'text-success'
          },
          i18n: '',
          icon: 'USER_TURNOVER',
          iconPack: 'useral'
        },
        {
          event: 'userMaxDebt',
          name: 'userMaxDebt',
          // params: {id: this.userId || this.$route.params.id},
          header: this.$t('users.user.profile.actions.maxDebt'),
          extra: {
            value: '',
            // color: 'danger'
          },
          i18n: '',
          icon: 'DOLLAR_SIGN',
          permission: 'user.set_max_debt',
          iconPack: 'useral'
        },
        {
          event: 'userInvoices',
          name: 'userInvoices',
          params: {id: this.userId || this.$route.params.id},
          header: this.$t('users.user.profile.actions.invoices'),
          // preview: {
          //   value: addComma(25000) + this.$locale.currency(),
          //   color: 'success'
          // },
          i18n: '',
          icon: 'INVOICE',
          iconPack: 'useral'
        },
        {
          event: 'insertSaleInvoice',
          name: 'insertSaleInvoice',
          query: {user: this.userId || this.$route.params.id},
          header: this.$t('users.user.profile.actions.reserve'),
          i18n: '',
          icon: 'icon-clock',
          iconPack: 'feather',
          permission: 'invoice.create'
        },
        {
          event: 'customerSeasonSchedulesList',
          name: 'customerSeasonSchedulesList',
          params: {userId: this.userId || this.$route.params.id},
          header: this.$t('users.user.profile.actions.reserveSeason'),
          i18n: '',
          icon: 'CALENDER',
          iconPack: 'useral',
          permission: 'invoice.create'
        },
        {
          event: 'insertTreasuryReceive',
          name: 'insertTreasuryReceive',
          query: {user: this.userId || this.$route.params.id},
          header: this.$t('users.user.profile.actions.receive'),
          i18n: '',
          // color: 'success',
          icon: 'DOLLAR_SIGN',
          iconPack: 'useral'
        },
        {
          event: 'userActivitiesLog',
          name: 'userActivitiesLog',
          params: {id: this.userId || this.$route.params.id},
          header: this.$t('users.user.profile.actions.logs'),
          i18n: '',
          icon: 'LAST_ACTIVITIES',
          iconPack: 'useral',
          permission: 'activity_logs.show'
        }
      ]
    }
  },
  created () {
    //check permissions
    if (!checkUserPermissions('user.update')) {
      delete this.actions.toolbar
    }

    if (!this.userId) {
      this.$nextTick(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'profile-page-navbar mx-auto')
      })
    }

    this.getUser()
  },
  computed: {
    getUserProfile () {
      const user = {
        avatar: this.user.avatar ? getAvatarUrl(this.user.avatar) : this.user.general.gender === 1 ? require('@/assets/images/man-avatar.png') : require('@/assets/images/woman-avatar.png'),
        name: `${this.user.general.name.value} ${this.user.general.lastName.value}`,
        general: this.user.general,
        importantData: [
          // {
          //   value: `${this.user.general.name.value} ${this.user.general.lastName.value}`,
          //   key: this.user.general.gender === 1 ? this.$t('users.genderTypes.dynamicMan') : this.user.general.gender === 2 ? this.$t('users.genderTypes.woman') : ''
          // },
          {
            value: this.user.general.phoneNumber.value,
            key: this.$t('users.user.profile.asiderAvatar.phoneNumber'),
            valueClass: this.$vs.rtl ? 'rtl' : 'ltr'
          },
          // {
          //   value: this.user.general.character ?  this.user.general.character.name : '',
          //   key: this.$t('users.user.profile.asiderAvatar.character')
          // },
          {
            value: this.user.general.dailyDebt > 0 ? `${addComma(this.user.general.dailyDebt)} ${this.$locale.currency()}` : this.$t('users.user.labels.notHave'),
            key: this.$t('users.user.profile.asiderAvatar.todayDebt'),
            valueClass: this.user.general.dailyDebt > 0 ? 'text-danger' : 'text-success'
          }
        ],
        details: [],
      }

      return user
    },
    getUserTransactions () {
      return {
        value: this.user.financial.balance || 0
      }
    }
  },
  methods: {
    getUser () {

      getUser(this.userId || this.$route.params.id).then(res => {
        const user = res.data.data

        this.user = {
          general: {
            name: {
              value: user.name,
              isInvalid: ''
            },
            lastName: {
              value: user.family,
              isInvalid: ''
            },
            gender: user.gender || '',
            character: user.character,
            companyName: {
              value: user.company ? user.company : '',
              isInvalid: ''
            },
            email: {
              value: user.email ? user.email : '',
              isInvalid: ''
            },
            phoneNumber: {
              value: user.phone_number,
              isInvalid: ''
            },
            dailyDebt: user.daily_debt || 0
            // type: type,
          },
          coachId: user.coach_id,
          avatar: user.avatar || '',
          access: user.role,
          group: user.group,
          financial: {
            maxDebt: user.max_debt ? user.max_debt : 0,
            balance: user.balance ? user.balance : 0
          }
        }

        const balance_index = this.actionsList.map(e => e.event).indexOf('userTransactions')
        let actionsList = JSON.parse(JSON.stringify(this.actionsList))
        if (balance_index > -1) {
          actionsList[balance_index].extra = {
            value: Math.abs(this.user.financial.balance) || 0,
            type: 'price',
            classes: this.user.financial.balance < 0 ? 'text-success' : this.user.financial.balance > 0 ? 'text-danger' : ''
          }
        }

        const maxDebt_index = this.actionsList.map(e => e.event).indexOf('userMaxDebt')
        if (maxDebt_index > -1) {
          actionsList[maxDebt_index].extra.value = `${addComma(Math.abs(this.user.financial.maxDebt)) || 0} ${this.$locale.currency()}`
        }

        if (user.is_coach) {
          const coachClasses = {
            event: 'userClasses',
            name: 'userClasses',
            i18n: 'users.user.profile.actions.coachClasses',
            icon: 'CALENDER',
            iconPack: 'useral',
          }

          actionsList.splice(2, 0, coachClasses)
        }

        this.actionsList = []
        setTimeout(() => {
          this.actionsList = actionsList
        }, 50)


        if (!this.user.access) {
          this.actionsList.splice(2, 1)
        }

        if (!this.userId && !this._isBeingDestroyed) {
          this.$store.dispatch('setPageTitle', this.$t('users.user.profile.dynamicTitle', {name: `${this.user.general.name.value} ${this.user.general.lastName.value}`}))
        }
      })
    },
    handleChangeProfileStatus (status) {
      this.profileStatus = ''
      this.$nextTick(() => {
        this.profileStatus = status
      })
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  },
  watch: {
    '$store.state.helper.documentsChanged': {
      handler (val) {
        if (val) {
          this.getUser()
          this.$emit('edited')
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.user-profile-page {

  .vx-card__body {
    padding: 0 !important;

    .profile-main-box2 {
      padding: 0;
    }
  }
}

.action-buttons {
  display: flex;

  button {
    flex: 1;
    line-height: 40px;
    border-radius: .5rem;
    border: none;
    color: #ffffff;
    transition: all .3s ease;
    cursor: pointer;

    &.payment {
      margin-right: 10px;
      background: #33b000cf;

      &:hover {
        background: #33b000;
      }
    }

    &.cancel {
      background: #b00000af;

      &:hover {
        background: #b00000;
      }
    }
  }
}
</style>
